
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import { assigneeOptions, priorityOptions, ticketsStatusOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: {
    TmFormLine,
    TmButton,
    TmModal,
  },
  setup() {
    const status = ref(ticketsStatusOptions[0])
    const statusList = ref(ticketsStatusOptions)
    const priority = ref('200')
    const priorityList = ref(priorityOptions)
    const assignee = ref(assigneeOptions[2])
    const subject = ref('')
    const requester = ref(assigneeOptions[4])
    const requesterEmail = ref()
    const inbox = ref('support@company.com')
    const inboxList = ref(['support@company.com', 'sales@company.com', 'admin@company.com'])

    return {
      status,
      statusList,
      priority,
      priorityList,
      assignee,
      requesterEmail,
      assigneeOptions,
      subject,
      requester,
      inbox,
      inboxList,
    }
  },
})
